import {
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type ColorResult } from 'react-color';

import {
  ChromePickerContainer,
  ColorPickerFieldContainer,
  ColorPickerIcon,
  ColorPreview,
  CustomChromePicker,
  ErrorMessage,
  ErrorMessageContainer,
  Fieldset,
  Label,
} from './styles';

type IColorKey = string;

export interface IFormColorPickerContentProps<K extends IColorKey>
  extends Pick<HTMLAttributes<HTMLFieldSetElement>, 'className' | 'style'> {
  children: ReactNode;
  color: string;
  colorKey?: K;
  customPreview?: ReactNode;
  dataCy?: string;
  errorMessage?: string;
  filled: boolean;
  inputRef: string;
  isSelectData?: boolean;
  label: string;
  onChange?: (color: string, colorKey: K | undefined) => void;
  value?: string;
}

export const FormColorPickerContent = <K extends IColorKey>({
  children,
  className,
  color,
  colorKey,
  customPreview,
  dataCy = 'no-color',
  errorMessage,
  filled,
  inputRef,
  isSelectData = false,
  label,
  onChange,
  style,
  value,
}: IFormColorPickerContentProps<K>): JSX.Element => {
  const showLabel = ((isSelectData && value !== undefined && label !== '') ||
    (!isSelectData && label !== '')) satisfies boolean;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  const handleColorChange = useCallback(
    (newColor: ColorResult) => {
      onChange?.(newColor.hex, colorKey);
    },
    [colorKey, onChange]
  );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node | null)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {showColorPicker && (
        <ChromePickerContainer
          data-cy={`${dataCy}-picker-container`}
          ref={colorPickerRef}
        >
          <CustomChromePicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChange}
            disableAlpha
          />
        </ChromePickerContainer>
      )}
      <ColorPickerFieldContainer>
        {customPreview ?? <ColorPreview $color={color} />}

        <Fieldset
          data-cy={label}
          filled={filled}
          isInvalid={!!errorMessage}
          className={className}
          style={style}
        >
          {showLabel && (
            <Label htmlFor={inputRef} data-cy={dataCy}>
              {label}
            </Label>
          )}

          {children}

          <ColorPickerIcon
            onClick={() => {
              if (color) {
                setShowColorPicker(!showColorPicker);
              }
            }}
          />
        </Fieldset>
      </ColorPickerFieldContainer>
      <ErrorMessageContainer>
        <ErrorMessage data-cy={`error-${dataCy ?? ''}`}>
          {errorMessage}
        </ErrorMessage>
      </ErrorMessageContainer>
    </>
  );
};
