import { useCallback, useRef, useState } from 'react';

import {
  FormModal,
  type IBaseModalProps,
  SelectData,
  TemplatesType,
  templatesTypes,
  useToast,
} from '@gbs-monorepo-packages/common';

import { useCourse } from '../../../../hooks/useCourse';
import {
  type ICoursePageDTO,
  createCoursePageFromTemplate,
} from '../../../../services/coursePages';
import { generateNegativeTimestamp } from '../../../../utils/generate';
import Logger from '../../../../utils/logger';
import { SelectTemplates } from '../SelectTemplates';
import { Fieldset, Input, Label, SelectContainer } from './styles';

interface IAddPageModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  companyId: number;
  onAccept: (course: ICoursePageDTO) => void;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
}

const fromScratch = TemplatesType.BUILD_SCRATCH;

export const AddPageModal = ({
  companyId: clientId,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  zIndex,
  ...props
}: IAddPageModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const [templateOptionsId, setTemplateOptionsId] =
    useState<number>(fromScratch);
  const [pageTitle, setPageTitle] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [loadingTemplateContent, setLoadingTemplateContent] =
    useState<boolean>(false);
  const { addToast } = useToast();
  const { selectedCourse } = useCourse();

  const requiredFields = Boolean(
    (pageTitle.trim() && templateId) ||
      (pageTitle.trim() && templateOptionsId === fromScratch)
  );

  const handleAddPage = useCallback(async () => {
    const newTitle = pageTitle.trim();
    const templateIdNum = parseInt(templateId);
    if (!newTitle) {
      return;
    }

    setLoadingTemplateContent(true);
    if (!isNaN(templateIdNum)) {
      try {
        const newPage: ICoursePageDTO = await createCoursePageFromTemplate({
          courseId: selectedCourse?.id ?? 0,
          templateId: templateIdNum,
          title: newTitle,
        });

        onAccept?.(newPage);
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          dataCy: 'add-page-from-template-error-toast',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          title: 'Error on get template',
        });
      }
    } else {
      const tempId = generateNegativeTimestamp();

      const newPage: ICoursePageDTO = {
        components: '{}',
        createdAt: '',
        cssContent: '',
        hasWrapper: true,
        htmlContent: '',
        id: tempId,
        pageNumber: 0,
        title: newTitle,
        updatedAt: '',
      };

      onAccept?.(newPage);
    }

    setPageTitle('');
    setTemplateId('');
    setTemplateOptionsId(fromScratch);
    setLoadingTemplateContent(false);
  }, [addToast, onAccept, pageTitle, selectedCourse?.id, templateId]);

  const handleDeclineAddPage = useCallback(() => {
    if (!isSelectOpen.current.size) {
      onDecline?.();
      setPageTitle('');
      setTemplateId('');
      setTemplateOptionsId(fromScratch);
    }
  }, [onDecline]);

  const handleOnTemplateChange = useCallback((id: string) => {
    setTemplateId(id);
  }, []);

  const handleChangeTemplateOption = useCallback((templateTypeStr: string) => {
    const newTemplateOptionsId = Number(templateTypeStr);
    if (newTemplateOptionsId === fromScratch) {
      setTemplateId('');
    }
    setTemplateOptionsId(newTemplateOptionsId);
  }, []);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  return !open ? null : (
    <FormModal
      loading={loadingTemplateContent}
      dataCy="add-course-page-form-modal"
      {...props}
      open={!waitToOpen}
      acceptText="Create course page"
      declineText="Cancel"
      mainText="Create"
      onAccept={() => {
        void handleAddPage();
      }}
      onDecline={handleDeclineAddPage}
      onOpenChange={handleDeclineAddPage}
      zIndex={zIndex}
      disabled={!requiredFields}
    >
      <Fieldset data-cy="fieldSet-pageTitle" filled={!!pageTitle}>
        <Input
          id="page-title"
          autoComplete="off"
          data-cy="input-pageTitle"
          name="page-title"
          value={pageTitle}
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setPageTitle(e.target.value);
            }
          }}
          required
          type="text"
        />
        <Label data-cy="label-pageTitle" htmlFor="page-title">
          Title
        </Label>
      </Fieldset>

      <SelectContainer data-cy="template-type">
        <SelectData
          defaultValue={String(templateOptionsId)}
          data={templatesTypes}
          placeholder="Choose a template type"
          onValueChange={handleChangeTemplateOption}
          onOpenChange={(isOpen) => {
            handleOpenChange(isOpen, 'type');
          }}
          required
          zIndex={10}
        />
      </SelectContainer>
      {templateOptionsId !== fromScratch && (
        <SelectContainer data-cy="template-content">
          <SelectTemplates
            attribute="title"
            clientId={clientId}
            templateOptionsId={templateOptionsId}
            disabled={loadingTemplateContent}
            onValueChange={handleOnTemplateChange}
            onOpenChange={(isOpen) => {
              handleOpenChange(isOpen, 'options');
            }}
            required
            zIndex={10}
          />
        </SelectContainer>
      )}
    </FormModal>
  );
};
