import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type ColorResult } from 'react-color';

import { type IAdditionalColors } from '../../constants/GlobalStyles';
import { useCourse } from '../../hooks/useCourse';
import {
  ColorPickerContainer,
  ColorPickerIcon,
  ColorPreview,
  CustomChromePicker,
  ErrorMessage,
  ErrorMessageContainer,
  Fieldset,
  Label,
  SelectDataCustom,
  Square,
} from './styles';

interface ISelectColorsData {
  primaryColorTemplate: string;
  secondaryColorTemplate: string;
  backgroundColorTemplate: string;
  accentColorTemplate: string;
  colorPicker: string;
  additionalColors: IAdditionalColors;
}

interface IColorData {
  key: string;
  value: JSX.Element;
  color: string;
}

interface IColorPickerSelectDataProps {
  color: string;
  customInput?: (handleColorChange: (hex: string) => void) => ReactNode;
  customPreview?: ReactNode;
  dataCy: string;
  errorMessage?: string;
  label: string;
  onChange: (color: string, colorKey: string | null) => void;
  colorKey?: string | null;
}

export const ColorPickerSelectData = ({
  color,
  customInput,
  customPreview,
  dataCy,
  errorMessage,
  label,
  onChange,
  colorKey = null,
}: IColorPickerSelectDataProps): JSX.Element => {
  const { selectedCourse } = useCourse();
  const [error, setError] = useState<string>('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  const [hexColorValue, setHexColorValue] = useState(color);

  const handleColorChange = useCallback(
    (newColor: ColorResult) => {
      onChange(newColor.hex, colorKey);
      setHexColorValue(newColor.hex);
    },
    [colorKey, onChange]
  );

  const handleParagraphHexChange = (hex: string) => {
    if (hex.length <= 7) {
      setHexColorValue(hex);
      if (/^#([A-Fa-f0-9]{6})$/i.test(hex)) {
        onChange(hex, colorKey);
        setError('');
      } else {
        setError('Invalid color (Example: #000000)');
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node | null)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const globalStyle = selectedCourse?.globalStyle;

  const colorPickerSelectDataDefault = useMemo(() => {
    const defaultColor: ISelectColorsData = {
      primaryColorTemplate: '',
      secondaryColorTemplate: '',
      backgroundColorTemplate: '',
      accentColorTemplate: '',
      colorPicker: 'Custom Color Picker',
      additionalColors: {},
    };
    if (globalStyle?.templateColors) {
      const { templateColors, additionalColors } = globalStyle;
      if (templateColors.primaryColor) {
        defaultColor.primaryColorTemplate = 'Template Primary Color';
      }
      if (templateColors.secondaryColor) {
        defaultColor.secondaryColorTemplate = 'Template Secondary Color';
      }
      if (templateColors.backgroundColor) {
        defaultColor.backgroundColorTemplate = 'Template Background Color';
      }
      if (templateColors.accentColor) {
        defaultColor.accentColorTemplate = 'Template Accent Color';
      }
      if (additionalColors.length) {
        defaultColor.additionalColors =
          Array.isArray(additionalColors) &&
          additionalColors.at(0) &&
          typeof additionalColors.at(0) === 'object'
            ? (additionalColors.at(0) as Record<string, string>)
            : {};
      }
      return {
        ...defaultColor,
      };
    }
    return defaultColor;
  }, [globalStyle]);

  const [selectDataColors, setSelectDataColors] = useState<ISelectColorsData>(
    colorPickerSelectDataDefault
  );

  useEffect(() => {
    if (/^#([A-Fa-f0-9]{6})$/i.test(hexColorValue)) {
      onChange(hexColorValue, colorKey);
      setError('');
      setSelectDataColors((prevSelectDataColors) => ({
        ...prevSelectDataColors,
        colorPicker: hexColorValue,
      }));
    } else {
      setError('Invalid color (Example: #000000)');
    }
  }, [hexColorValue]);

  const handleSelectDataColor = useCallback(
    (value: string) => {
      if (value.startsWith('#')) {
        setHexColorValue(value);
      } else {
        const templateKey: string = value.replace('Template', '');
        // @ts-expect-error: value is a string and exists in templateColors
        setHexColorValue(String(globalStyle?.templateColors?.[templateKey]));
      }
    },
    [globalStyle]
  );

  const colorPickerData = useMemo(() => {
    const draft: IColorData[] = [];

    for (const [key, value] of Object.entries(selectDataColors)) {
      let color;

      if (key.startsWith('additionalColors')) {
        if (typeof value === 'string') {
          color = value;
        } else if (typeof value === 'object') {
          Object.entries(value as Record<string, string>).forEach(
            ([innerKey, innerColor]) => {
              draft.push({
                key: innerKey,
                value: (
                  <>
                    <Square color={innerColor} />
                    {innerKey}
                  </>
                ),
                color: innerColor,
              });
            }
          );
        }
      } else {
        if (key === 'colorPicker') {
          color = hexColorValue;
        } else {
          const templateKey = key.replace('Template', '');
          const templateColor = String(
            // @ts-expect-error: templateKey is a string and exists in templateColors
            globalStyle?.templateColors?.[templateKey] ?? ''
          );
          if (!templateColor) {
            continue;
          }
          color = templateColor;
        }

        const isSelected =
          color === hexColorValue &&
          typeof value === 'string' &&
          !value.includes('Template');

        draft.push({
          key,
          value: (
            <>
              {!isSelected && <Square color={color} />}
              {value}
            </>
          ),
          color,
        });
      }
    }

    // Organize the data so that the colorPicker is always at the end
    const colorPickerIndex = draft.findIndex((d) => d.key === 'colorPicker');
    if (colorPickerIndex !== -1) {
      const [colorPicker] = draft.splice(colorPickerIndex, 1);
      draft.push(colorPicker);
    }

    return draft;
  }, [selectDataColors, hexColorValue, globalStyle]);

  return (
    <>
      {showColorPicker && (
        <div data-cy={`${dataCy}-picker-container`} ref={colorPickerRef}>
          <CustomChromePicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChange}
            disableAlpha
          />
        </div>
      )}
      <ColorPickerContainer>
        {customPreview ?? <ColorPreview style={{ backgroundColor: color }} />}

        <Fieldset
          data-cy={`field-set`}
          filled={!!color}
          isInvalid={!!(error || errorMessage)}
        >
          {customInput
            ? customInput(handleParagraphHexChange)
            : selectDataColors.colorPicker && (
                <SelectDataCustom
                  data={colorPickerData}
                  data-cy={`${dataCy}-input`}
                  onValueChange={handleSelectDataColor}
                  value={'colorPicker'}
                  zIndex={10}
                  required
                  name="colorPicker"
                />
              )}

          <Label data-cy={`${dataCy}-label`}>{label}</Label>
          <ColorPickerIcon
            onClick={() => {
              setShowColorPicker(!showColorPicker);
            }}
          />
        </Fieldset>
      </ColorPickerContainer>
      {(error || errorMessage) && (
        <ErrorMessageContainer>
          <ErrorMessage data-cy={error || errorMessage}>
            {error || errorMessage}
          </ErrorMessage>
        </ErrorMessageContainer>
      )}
    </>
  );
};
