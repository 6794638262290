import { type ReactNode, useCallback, useState } from 'react';

import { FormColorPickerContent } from '../../../../components/FormColorPickerContent';
import { SmallInputTextCustom } from './styles';

interface IColorPickerInputProps<K extends string> {
  color: string;
  customInput?: (handleColorChange: (hex: string) => void) => ReactNode;
  customPreview?: ReactNode;
  dataCy?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  name: K;
  onChange?: (color: string, colorKey?: K) => void;
  required?: boolean;
}
export const ColorPickerInput = <K extends string>({
  color,
  customInput,
  customPreview,
  dataCy = 'no-color',
  disabled = false,
  errorMessage,
  label,
  name: keyPath,
  onChange,
  required = true,
}: IColorPickerInputProps<K>): JSX.Element => {
  const [hexColorValue, setHexColorValue] = useState(color);

  const handleColorChange = useCallback(
    (newColor: string, colorKey = keyPath) => {
      onChange?.(newColor, colorKey);
      setHexColorValue(newColor);
    },
    [keyPath, onChange]
  );

  const handleParagraphHexChange = useCallback(
    (hex: string) => {
      onChange?.(hex, keyPath);
      setHexColorValue(hex);
    },
    [keyPath, onChange]
  );

  return (
    <FormColorPickerContent
      color={color}
      colorKey={keyPath}
      customPreview={customPreview}
      dataCy={`${dataCy}-input`}
      errorMessage={errorMessage}
      filled={Boolean(hexColorValue)}
      inputRef={`${dataCy}-input`}
      label={label ?? 'Color'}
      onChange={handleColorChange}
    >
      {customInput ? (
        customInput(handleParagraphHexChange)
      ) : (
        <SmallInputTextCustom
          dataCy={`${dataCy}-input`}
          defaultValue={hexColorValue}
          disabled={disabled}
          id={keyPath}
          name={keyPath}
          onChange={(e) => {
            handleParagraphHexChange(e.target.value);
          }}
          required={required}
          type="text"
        />
      )}
    </FormColorPickerContent>
  );
};
