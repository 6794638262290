import { type AxiosRequestConfig } from 'axios';

import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  api,
} from './api';
import { type ICompanyDTO } from './companies';
import { type ICoursePageDTO } from './coursePages';
import { type IFontDTO } from './courses';

const templatePath = 'api/page/template';
const groupTemplatePath = 'api/course-template';

export const templateBaseHtml = '<body class="content-center"></body>';
export const templateBaseCss =
  '* {box-sizing: border-box;} body {margin: 0;} .content-center{ padding:0 calc((100% - 500px)/2); } img{ max-width:100%!important; } div[data-gjs-type="video"]{ max-width:100%!important; width:unset!important; } body{ color:null; font-size:null; }';

export interface ITemplateOptionsDTO {
  id: number;
  name: string;
}

export type IMatchTemplateColors = Record<string, string>;

export interface ICourseTemplateGroupOrder {
  pageOrder: number;
  courseTemplate: {
    id: number;
  };
}

export interface ITemplateDTO {
  id: number;
  cssContent: string | null;
  htmlContent: string;
  hasWrapper?: boolean;
  title: string;
  createdAt: string;
  updatedAt: string;
  templateOptions?: ITemplateOptionsDTO | number | undefined;
  idExternal?: string | null;
  components?: string | null;
  templateColorsFound?: IMatchTemplateColors | null;
  courseTemplatePageOrders?: ICourseTemplateGroupOrder[];
}

export interface ITemplateDTOWithOrder extends ITemplateDTO {
  order: number;
}

export interface ITemplatePageDTO
  extends Omit<ICoursePageDTO, 'pageNumber' | 'type'> {
  company?: Pick<ICompanyDTO, 'id' | 'name'> | null;
  title: string;
  isGlobal?: boolean;
  fonts?: IFontDTO[];
  id: number;
  userCreatedId?: number;
  templateOptionsId?: number;
}

export interface ITemplatePageMinimizedDTO
  extends Pick<ICoursePageDTO, 'id' | 'title'>,
    Partial<Pick<ICoursePageDTO, 'cssContent' | 'htmlContent'>> {
  company?: never[] | Pick<ICompanyDTO, 'id' | 'name'> | null;
  templateOptionsId?: number;
}

export type ITemplatePageContentId = Pick<ITemplatePageDTO, 'id'>;
export type ITemplatePageContent = Omit<
  ITemplatePageDTO,
  'createdAt' | 'id' | 'updatedAt'
>;
export interface ITemplatePageWithOrder extends ITemplatePageDTO {
  order: number;
}

export interface ICreateTemplatePageProps extends ITemplatePageContent {
  courseId?: number;
  companyId: number;
  templateOptions: number;
  components: string;
}

export type IDeleteTemplatePageProps = ITemplatePageContentId;

export type IGetTemplatePageProps =
  | ITemplatePageContentId
  | {
      id: string;
    };

export interface IGetTemplatePagesByCompanyIdProps {
  clientId: number;
  templateOptionsId: number | string;
  page: number;
  limit: number;
  filter: string;
}

export type IUpdateTemplatePage = ITemplateDTO;

export interface IPaginationTemplatesDTO {
  data: ITemplatePageDTO[];
  meta: IPaginationMetaProps;
}

export const createTemplatePage = async ({
  ...data
}: ICreateTemplatePageProps): Promise<ITemplateDTO> => {
  const result = await api
    .post<IResponseData<ITemplateDTO>>(`${templatePath}/`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteTemplatePage = async ({
  id,
}: IDeleteTemplatePageProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${templatePath}/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getTemplatePage = async ({
  id,
}: IGetTemplatePageProps): Promise<ITemplatePageDTO> => {
  const result = await api
    .get<IResponseData<ITemplatePageDTO>>(`${templatePath}/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getTemplatePagesByCompanyId = async (
  {
    clientId,
    templateOptionsId,
    page,
    limit,
    filter,
  }: IGetTemplatePagesByCompanyIdProps,
  config?: AxiosRequestConfig
): Promise<IPaginationTemplatesDTO> => {
  const result = await api
    .get<IPaginationResponseData<ITemplatePageDTO[]>>(
      `${templatePath}/template-options/${templateOptionsId}/${clientId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`,
      config
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateTemplatePage = async ({
  id,
  ...data
}: IUpdateTemplatePage): Promise<{ message: string }> => {
  const result = await api
    .patch<IResponseData>(`${templatePath}/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export interface ITemplateGroupOrder {
  pageOrder: number;
  pageTemplate: {
    id: number;
  };
}

export interface ITemplateGroupDTO {
  id: number;
  clientId: number;
  title: string;
  templateOptions: ITemplateOptionsDTO;
  userCreatedId: number;
  pageTemplates?: Record<number, number>;
  courseTemplatePageOrders: ITemplateGroupOrder[];
}

export interface IGetTypeGroupTemplateProps {
  templateOptionsId: number;
  page: number;
  limit: number;
  filter: string;
}

export interface ITemplateGroupUpdate
  extends Pick<
    ITemplateGroupDTO,
    'id' | 'pageTemplates' | 'templateOptions' | 'title'
  > {}

export interface ICreateTemplateGroup
  extends Omit<
    ITemplateGroupDTO,
    | 'clientId'
    | 'courseTemplatePageOrders'
    | 'id'
    | 'templateOptions'
    | 'userCreatedId'
  > {
  templateOptions: number;
  client: number;
}

export interface IGetIdGroupTemplateProps {
  templateGroupId: number;
}

export type ITemplateGroupPageContentId = Pick<ITemplateGroupDTO, 'id'>;

export type ITemplateGroupUpdateId = ITemplateGroupUpdate;

export type ICreateTemplateGroupProps = ICreateTemplateGroup;

export interface ICreateTemplateGroupByCourseProps {
  courseId: number;
  templateOptions: number;
}

export type IUpdateTemplateGroupProps = Pick<
  ITemplateGroupDTO,
  'id' | 'pageTemplates' | 'title'
> & { templateOptions: number };

export type IDeleteTemplateGroupPageProps = ITemplateGroupPageContentId;

export type IDeletePageTemplateGroupProps = Pick<ITemplateGroupDTO, 'id'> & {
  pageTemplateId: number;
};

export interface IPaginationTemplateGroupsDTO {
  data: ITemplateGroupDTO[];
  meta: IPaginationMetaProps;
}

export interface ITemplateGroupPages {
  data: ITemplateDTO[];
}

export const getTemplateGroupsByCompanyId = async ({
  templateOptionsId,
  page,
  limit,
  filter,
}: IGetTypeGroupTemplateProps): Promise<IPaginationTemplateGroupsDTO> => {
  const result = await api
    .get<IPaginationResponseData<ITemplateGroupDTO[]>>(
      `${groupTemplatePath}/template-options/${templateOptionsId}?page=${page}&limit=${limit}&filter=${filter}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getTemplateGroupPages = async ({
  templateGroupId,
}: IGetIdGroupTemplateProps): Promise<ITemplateGroupPages> => {
  const result = await api
    .get<ITemplateGroupPages>(
      `${groupTemplatePath}/page-template/${templateGroupId}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createTemplateGroup = async ({
  ...data
}: ICreateTemplateGroupProps): Promise<ITemplateGroupDTO> => {
  const result = await api
    .post<IResponseData<ITemplateGroupDTO>>(`${groupTemplatePath}/`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateTemplateGroup = async ({
  id,
  ...data
}: IUpdateTemplateGroupProps): Promise<ITemplateGroupDTO> => {
  const result = await api
    .patch<IResponseData<ITemplateGroupDTO>>(`${groupTemplatePath}/${id}`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
export const deleteTemplateGroup = async ({
  id,
}: IDeleteTemplateGroupPageProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${groupTemplatePath}/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deletePageTemplateGroup = async ({
  id,
  pageTemplateId,
}: IDeletePageTemplateGroupProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(
      `${groupTemplatePath}/${id}/page-template/${pageTemplateId}`
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const createTemplateGroupByCourse = async ({
  courseId,
  templateOptions,
}: ICreateTemplateGroupByCourseProps): Promise<ITemplateGroupDTO> => {
  const data = { templateOptions };
  const result = await api
    .post<IResponseData<ITemplateGroupDTO>>(
      `${groupTemplatePath}/course/${courseId}`,
      data
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
