import { useCallback } from 'react';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';

export interface IWarningGlobalStyleModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: () => Promise<void> | void;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  mainText: string;
  dataCy?: string;
  acceptText?: string;
}

export const WarningDefaultModal = ({
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  mainText,
  dataCy = 'save-dialogModal',
  acceptText = 'Accept',
  ...props
}: IWarningGlobalStyleModalProps): JSX.Element | null => {
  const handleAccept = useCallback(async () => {
    await onAccept?.();
  }, [onAccept]);

  const handleDecline = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  return !open ? null : (
    <DialogModal
      acceptText={acceptText}
      dataCy={dataCy}
      declineText="Cancel"
      mainText={mainText}
      {...props}
      onAccept={() => {
        void handleAccept();
      }}
      onDecline={handleDecline}
      onOpenChange={handleDecline}
      open={!waitToOpen}
    />
  );
};
