import { z } from 'zod';

export const color = z
  .string()
  .regex(
    /^(#[0-9A-F]{6})?$/i,
    'Color must be a valid hex color (Example: #000000)'
  );

export const colorRequired = color.min(1, 'Color is required');
