import { type AxiosError, isAxiosError } from 'axios';
import type * as CSS from 'csstype';
import type { Editor, SelectOption } from 'grapesjs';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  DefaultDescriptionBenefitEducation,
  type IApiThrowsError,
  type IErrorDefaultContent,
  INIT_PAGE,
  ManagerRoles,
  NO_LIMIT_PAGE,
  Roles,
  useToast,
} from '@gbs-monorepo-packages/common';

import { FullscreenLoader } from '../../components/FullscreenLoader';
import { handlerSetDefaultRules } from '../../components/GrapesJS/Customs';
import { updateSrcVideoAttribute } from '../../components/GrapesJS/Customs/Default';
import {
  handlerWrapper,
  wrapperClass,
} from '../../components/GrapesJS/Customs/ToggleWrapper';
import { WebBuilder } from '../../components/WebBuilder';
import {
  FREQUENCY_EDIT_TIME_UPDATE_SENT,
  GOOGLE_FONTS_API_URL,
} from '../../constants/Env';
import { type ICustomFont } from '../../constants/Fonts';
import { globalStyleDefault } from '../../constants/GlobalStyles';
import {
  type IEditCourseState,
  type ILocation,
} from '../../constants/RouteStates';
import {
  type ICoursePage,
  type IDeleteCoursePageInfo,
  type ITemplatePage,
} from '../../contexts/coursePage';
import { useCompanies } from '../../hooks/useCompanies';
import { useCourse } from '../../hooks/useCourse';
import { useCoursePages } from '../../hooks/useCoursePages';
import { useDragDrop } from '../../hooks/useDragDrop';
import {
  type IBatchCoursePagesToPatch,
  type ICoursePageDTO,
  type ICreateCoursePageProps,
  type IHistoryPage,
  type IPatchCoursePagesProps,
  type IUpdateCoursePageProps,
  deleteCoursePage,
  patchCoursePages,
} from '../../services/coursePages';
import {
  type ICourseDTO,
  type ICourseSettingsProps,
  type IFontDTO,
  updateCourseEditTime,
} from '../../services/courses';
import { getListAllDocumentsByFolder } from '../../services/documentsFolder';
import {
  type ITemplateDTO,
  createTemplatePage,
  updateTemplatePage,
} from '../../services/templates';
import { generateNegativeTimestamp } from '../../utils/generate';
import Logger from '../../utils/logger';
import { ByPageNumber } from '../../utils/sortObjects';
import { CoursePages } from './components/CoursePages';
import { SettingCourseHeader } from './components/SettingCourseHeader';
import {
  Container,
  LoadingCoursePages,
  LoadingCoursePagesContainer,
  MainContainer,
  MainContent,
  NoPagesContainer,
  NoPagesText,
} from './styles';

type ICoursePageEditorContent = Pick<
  ICoursePage,
  'components' | 'cssContent' | 'hasWrapper' | 'htmlContent' | 'idExternal'
>;

type ITemplatePageEditorContent = Pick<
  ITemplatePage,
  'components' | 'cssContent' | 'hasWrapper' | 'htmlContent' | 'idExternal'
>;

const checkEditorContentChanged = (
  currentEditor: Editor,
  { cssContent, hasWrapper, components }: ICoursePageEditorContent
) => {
  const wrapperClasses: string[] =
    (currentEditor.getWrapper()?.getClasses() as string[]) ?? [];

  const hasEditorWrapper: boolean = wrapperClasses.includes(wrapperClass);
  if (hasWrapper !== hasEditorWrapper) return true;

  const cssEditorContent = currentEditor.getCss({ json: false }) as string;
  if (cssContent !== cssEditorContent) return true;

  const componentsEditorContent = JSON.stringify(
    currentEditor.getWrapper()?.toJSON()
  );

  components = JSON.stringify(JSON.parse(components ?? ''));
  if (components !== componentsEditorContent) return true;

  return false;
};

const checkContentChanged = (
  {
    cssContent: newCssContent,
    htmlContent: newHtmlContent,
    hasWrapper: newHasWrapper,
    components: newComponents,
  }: ICoursePageEditorContent,
  { cssContent, htmlContent, hasWrapper, components }: ICoursePageEditorContent
) => {
  return (
    hasWrapper !== newHasWrapper ||
    htmlContent !== newHtmlContent ||
    cssContent !== newCssContent ||
    components !== newComponents
  );
};

export const updateEditorWithContent = (
  currentEditor: Editor,
  {
    cssContent,
    htmlContent,
    idExternal,
    components,
    hasWrapper,
  }: ICoursePageEditorContent | ITemplatePageEditorContent
): void => {
  // check if there is style inside an html
  const regex = /<style(?=[\s>])(?:(?!<\/style>)[\s\S])*<\/style>|style\s*=/gi;
  const matches = htmlContent.match(regex);

  if (!matches) {
    if (!idExternal) {
      currentEditor.Css.clear();
    }
  }

  currentEditor.selectRemove(currentEditor.getSelectedAll());
  currentEditor.Components.clear();
  if (components) {
    const bkpComponents = JSON.parse(components) as object;
    currentEditor.Components.load(bkpComponents);
  } else {
    currentEditor.setComponents(htmlContent, {});
  }
  handlerSetDefaultRules(currentEditor, cssContent ?? '');
  handlerWrapper(currentEditor, hasWrapper);
  updateSrcVideoAttribute(currentEditor);
  currentEditor.UndoManager.clear();
};

interface IUpdateCoursePagesParams {
  courseId: number;
  coursePages: ICoursePage[];
  lastCoursePages: ICoursePageDTO[];
  updatedPages?: ICoursePage[];
}

const updateCoursePages = ({
  courseId,
  coursePages,
  lastCoursePages,
  updatedPages = [],
}: IUpdateCoursePagesParams) => {
  const pages: IBatchCoursePagesToPatch = [];

  coursePages.forEach((page, index) => {
    let templateColorsFoundAux;

    if (updatedPages.length > 0) {
      const updatedPage = updatedPages.find(
        (updatedPage) => updatedPage.id === page.id
      );

      templateColorsFoundAux = updatedPage?.templateColorsFound;
    } else {
      templateColorsFoundAux = page.templateColorsFound;
    }

    const {
      id,
      cssContent,
      edited,
      hasWrapper,
      htmlContent,
      isNew = false,
      pageNumber,
      title,
      isNewFromTemplate = false,
      components,
      templateColorsFound = templateColorsFoundAux,
    } = page;

    const position = index + 1;
    if (isNew || id < 0) {
      const pageUpdated: ICreateCoursePageProps = {
        courseId,
        cssContent,
        hasWrapper,
        htmlContent,
        pageNumber: position,
        title,
        components,
        templateColorsFound,
      };
      pages.push(pageUpdated);
      return;
    } else if (isNewFromTemplate) {
      // page already exists, but was not saved yet
      const pageUpdated: IUpdateCoursePageProps = {
        id,
        cssContent,
        hasWrapper,
        htmlContent,
        pageNumber: position,
        title,
        templateColorsFound: templateColorsFoundAux,
      };
      pages.push(pageUpdated);
      return;
    }

    if (edited ?? false) {
      const pageUpdated: IUpdateCoursePageProps = {
        id,
      };
      if (pageNumber !== position) {
        pageUpdated.pageNumber = position;
      }
      const lastIndex = lastCoursePages.findIndex((old) => old.id === id);
      if (lastIndex >= 0) {
        const oldPage = lastCoursePages[lastIndex];

        if (cssContent !== oldPage.cssContent) {
          pageUpdated.cssContent = cssContent;
        }
        if (htmlContent !== oldPage.htmlContent) {
          pageUpdated.htmlContent = htmlContent;
        }
        if (title !== oldPage.title) {
          pageUpdated.title = title;
        }
        if (hasWrapper !== oldPage.hasWrapper) {
          pageUpdated.hasWrapper = hasWrapper;
        }
        if (components !== oldPage.components) {
          pageUpdated.components = components;
        }
        if (templateColorsFound !== oldPage.templateColorsFound) {
          pageUpdated.templateColorsFound = templateColorsFound;
        }
        lastCoursePages.splice(lastIndex, 1);
      }
      pages.push(pageUpdated);
      return;
    }

    if (pageNumber !== position) {
      const pageUpdated: IUpdateCoursePageProps = {
        id,
        pageNumber: position,
      };
      pages.push(pageUpdated);
    }
  });

  return pages.length
    ? {
        courseId,
        pages,
      }
    : null;
};

const settingDocumentLinkPluginOption = async (folderId: string) => {
  const { data: documentsResponse } = await getListAllDocumentsByFolder({
    folderId,
    page: INIT_PAGE,
    limit: NO_LIMIT_PAGE,
  });

  const documentLinkOptions: SelectOption[] = documentsResponse.map(
    ({ path, name, folder, originalName }) => ({
      id: path,
      name: `${folder.name}/${name ?? originalName ?? 'unknown'}`,
    })
  );
  return documentLinkOptions;
};

const createFindById =
  (currentCoursePage: Pick<ICoursePage, 'id'>) => (page: ICoursePage) =>
    page.id === currentCoursePage.id;
const createFindByContent =
  (currentCoursePage: ICoursePage) =>
  ({ htmlContent, cssContent, title, hasWrapper, components }: ICoursePage) =>
    title === currentCoursePage.title &&
    hasWrapper === currentCoursePage.hasWrapper &&
    htmlContent === currentCoursePage.htmlContent &&
    cssContent === currentCoursePage.cssContent &&
    components === currentCoursePage.components;

export interface ICourseWebsocketMessage {
  id: number;
  type: string;
  message?: string;
  title?: string;
  section?: string;
}

export const EditCourse = (): JSX.Element => {
  const [editor, setEditor] = useState<Editor | null>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [loadingCourse, setLoadingCourse] = useState(false);
  const [loadingCoursePages, setLoadingCoursePages] = useState(false);
  const [loadingEditor, setLoadingEditor] = useState(false);

  const [needSave, setNeedSave] = useState(false);
  const [savingCourse, setSavingCourse] = useState(false);

  const {
    changeCoursePageContent,
    coursePages,
    templatePages,
    overwriteCoursePages,
    selectedCoursePageIndex,
    selectCoursePageIndex,
    syncCoursePages,
    syncTemplatePage,
    deleteMultipleCoursePages,
  } = useCoursePages();

  const { companyId = '', courseId = '', templateId = '' } = useParams();
  const {
    saveCourse,
    selectedCourse,
    selectCourseByID,
    updateCourse,
    updateTemplate,
    selectedTemplate,
    updateCourseSettings,
  } = useCourse();

  const { state }: ILocation<IEditCourseState> = useLocation();

  const customConfig = useMemo(() => ({ pageManager: { pages: [] } }), []);
  const [loadingPluginConfig, setLoadingPluginConfig] = useState(false);
  const [documentLinkPluginConfig, setDocumentLinkPluginConfig] = useState<
    SelectOption[]
  >([]);

  const lastCoursePages = useRef<ICoursePageDTO[]>([]);
  const lastTemplatePage = useRef<ITemplateDTO[]>([]);

  const { addToast } = useToast();
  const { isDragging } = useDragDrop();

  const [isAllowedToEdit, setIsAllowedToEdit] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [defaultFontsCount, setDefaultFontsCount] = useState(13);

  const { user, getCurrentRole } = useAuth();
  const { selectedCompany } = useCompanies();

  const isUserManager = useMemo(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    return (
      (currentRole?.key && ManagerRoles.includes(currentRole.key)) ?? false
    );
  }, [getCurrentRole, user]);

  const editTimeout = useRef<NodeJS.Timer | null>(null);

  const [isLockedByOtherUser, setIsLockedByOtherUser] = useState(false);

  useEffect(() => {
    deleteEditTimeouts();

    if (templateId) {
      setEditTemplateTimeout();
    } else if (courseId) {
      setEditCourseTimeout();
    }

    return () => {
      deleteEditTimeouts();
    };
  }, [isLockedByOtherUser]);

  const handleUpdateCourseEditTime = useCallback(() => {
    Logger.notice('Sending a Course Edit Update message to the server');

    updateCourseEditTime({ courseId: Number(courseId) })
      .then(() => {
        if (isLockedByOtherUser) {
          addToast({
            title: 'Course avaliable for editing',
            description:
              'Re-open the course to obtain the changes or override with yours',
            styleType: 'success',
            dataCy: 'course-is-avaliable-editing-toast',
            duration: FREQUENCY_EDIT_TIME_UPDATE_SENT - 1000,
          });
        }

        setIsLockedByOtherUser(false);
      })
      .catch((err: AxiosError | Error) => {
        setIsLockedByOtherUser(true);

        if (isAxiosError(err) && err.response && err.response.status === 423) {
          const errorData = err.response.data as IErrorDefaultContent;

          addToast({
            title: 'Course is locked',
            description: errorData.error.message,
            styleType: 'error',
            dataCy: 'course-is-locked-toast',
            duration: FREQUENCY_EDIT_TIME_UPDATE_SENT - 1000,
          });
        } else {
          Logger.error(
            'Error while sending the Course Edit Update message: ' + err.message
          );

          addToast({
            title: 'Error while updating course edit time',
            description: err.message,
            styleType: 'error',
            dataCy: 'course-edit-time-error-toast',
          });
        }
      });
  }, [addToast, courseId, isLockedByOtherUser]);

  const handleUpdateTemplateTime = useCallback(() => {
    Logger.notice('Sending a Template Edit Update message to the server');
  }, []);

  const deleteEditTimeouts = useCallback(() => {
    if (editTimeout.current) {
      clearInterval(editTimeout.current);

      editTimeout.current = null;

      Logger.success('Cleared all Edit Update intervals');
    }
  }, []);

  const setEditCourseTimeout = () => {
    editTimeout.current = setInterval(
      handleUpdateCourseEditTime,
      FREQUENCY_EDIT_TIME_UPDATE_SENT
    );
  };

  const setEditTemplateTimeout = () => {
    editTimeout.current = setInterval(
      handleUpdateTemplateTime,
      FREQUENCY_EDIT_TIME_UPDATE_SENT
    );
  };

  useEffect(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    if (currentRole?.key === Roles.ADMIN) {
      setIsUserAdmin(true);
    }
  }, [getCurrentRole, user]);

  useEffect(() => {
    const syncCourseOnMount = async () => {
      const courseIdFromPath = Number(courseId);

      if (!courseIdFromPath) {
        return;
      }
      const courseFromState = state?.course ?? null;
      setLoadingCourse(true);
      if (
        courseFromState?.globalStyle?.additionalColors &&
        courseFromState.id === courseIdFromPath
      ) {
        updateCourse(courseFromState);
      } else {
        await selectCourseByID(courseIdFromPath);
      }
      window.history.replaceState(null, '');
      setLoadingCourse(false);
      setIsAllowedToEdit(true);
    };

    void syncCourseOnMount();
  }, []);

  // For templates
  useEffect(() => {
    let mount = true;
    const syncPagesOnMount = async () => {
      if (!templateId) {
        return;
      }

      lastTemplatePage.current = [];

      setLoadingCoursePages(true);

      try {
        const result = await syncTemplatePage(Number(templateId));
        if (mount) {
          lastTemplatePage.current = result;
          updateTemplate(
            lastTemplatePage.current[lastTemplatePage.current.length - 1]
          );
          setIsAllowedToEdit(true);

          handleUpdateTemplateTime();
        }
      } catch (err) {
        addToast({
          title: 'Error on loading course pages',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          dataCy: 'load-course-pages-error-toast',
        });
      } finally {
        setLoadingCoursePages(false);
      }
    };

    void syncPagesOnMount();

    return () => {
      mount = false;
      deleteEditTimeouts();
    };
  }, []);

  // For Courses
  useEffect(() => {
    let mount = true;
    const syncPagesOnMount = async () => {
      const courseIdFromPath = Number(courseId);

      if (!courseIdFromPath) {
        return;
      }
      lastCoursePages.current = [];

      setLoadingCoursePages(true);
      try {
        const result = await syncCoursePages(courseIdFromPath);

        if (mount) {
          lastCoursePages.current = result;
          setLoadingCoursePages(false);
          setIsAllowedToEdit(true);

          handleUpdateCourseEditTime();
        }
      } catch (err) {
        addToast({
          title: 'Error on loading course pages',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          dataCy: 'load-course-pages-error-toast',
        });
      }
    };

    void syncPagesOnMount();

    return () => {
      mount = false;
      deleteEditTimeouts();
    };
  }, []);

  useEffect(() => {
    let mount = true;
    const syncDocumentLinks = async (companyId: string) => {
      if (!companyId) return;
      if (!selectedCompany) return;

      try {
        setLoadingPluginConfig(true);
        const result = await settingDocumentLinkPluginOption(
          String(selectedCompany?.defaultLmsFolder.id)
        );

        if (mount) {
          setDocumentLinkPluginConfig(result);
        }
      } catch (err) {
        addToast({
          title: 'Error on load document links',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          dataCy: 'load-document-links-error-toast',
        });
      } finally {
        if (mount) {
          setLoadingPluginConfig(false);
        }
      }
    };

    void syncDocumentLinks(companyId);

    return () => {
      mount = false;
    };
  }, [addToast, companyId]);

  useEffect(() => {
    const handleUpdate = () => {
      if (!needSave) {
        const oldCoursePage = lastCoursePages.current.at(
          selectedCoursePageIndex
        );
        if (!editor || !oldCoursePage) {
          setNeedSave(true);
        } else {
          const needToBeSaved = checkEditorContentChanged(
            editor,
            oldCoursePage
          );
          setNeedSave(needToBeSaved);
        }
      }
    };

    const addListenerEditorEvents = () => {
      if (editor) {
        editor.on('update', handleUpdate);
      }
    };

    addListenerEditorEvents();

    return () => {
      editor?.off('update', handleUpdate);
    };
  }, [
    coursePages,
    editor,
    needSave,
    selectCoursePageIndex,
    selectedCoursePageIndex,
  ]);

  useEffect(() => {
    const {
      passcodeProtected = false,
      viewURL = null,
      passcode = null,
      status = 'In Progress',
    } = selectedCourse ?? {};

    const settings: ICourseSettingsProps = {
      passcodeProtected,
      viewUrl: viewURL?.trim() ?? null,
      status: status ?? 'In Progress',
    };

    if (passcodeProtected) settings.passcode = passcode?.trim();

    updateCourseSettings(settings);
  }, [selectedCourse, updateCourseSettings]);

  useEffect(() => {
    const handleStopPreview = () => {
      if (editor && !isAllowedToEdit) {
        editor.runCommand('preview');
      }
    };

    if (editor) {
      editor.on('stop:preview', handleStopPreview);

      if (!isAllowedToEdit) {
        editor.runCommand('preview');
      }
    }

    return () => {
      editor?.off('stop:preview', handleStopPreview);
    };
  }, [editor, isAllowedToEdit]);

  const handleChangeTitle = () => {
    setNeedSave(true);
  };

  const handleUpdateTemplate = useCallback(async () => {
    if (!editor || !templateId || !selectedTemplate) return;

    const htmlContent = editor.getHtml();
    const components = editor.getWrapper()?.toJSON() ?? '';
    const cssContent = editor.getCss({ json: false }) as string;

    const wrapperClasses: string[] =
      (editor.getWrapper()?.getClasses() as string[]) ?? [];
    const hasWrapper: boolean = wrapperClasses.includes(wrapperClass);

    try {
      deleteEditTimeouts();

      await updateTemplatePage({
        id: Number(templateId),
        cssContent,
        hasWrapper,
        htmlContent,
        createdAt: '',
        updatedAt: '',
        components: JSON.stringify(components),
        title: selectedTemplate?.title,
      });

      setEditTemplateTimeout();

      const result = await syncTemplatePage(Number(templateId));
      lastTemplatePage.current = result;
      updateTemplate(
        lastTemplatePage.current[lastTemplatePage.current.length - 1]
      );
      setNeedSave(false);
      addToast({
        title: 'Template saved',
        styleType: 'success',
        dataCy: 'save-template-success-toast',
      });
    } catch (error) {
      addToast({
        title: 'Error on save template',
        description:
          'An error occurred. Please try again or contact Benefit Education support.',
        styleType: 'error',
        dataCy: 'save-template-error-toast',
      });
    }
  }, [
    addToast,
    editor,
    syncTemplatePage,
    templateId,
    updateTemplate,
    selectedTemplate,
  ]);

  const handleSavePageTemplateAndCourse = useCallback(
    async (title: string, templateOptions: number) => {
      const courseIdNum = parseInt(courseId);
      const companyIdNum = parseInt(companyId);
      if (title === '') {
        addToast({
          title: 'Error on save template',
          description: 'Need a description.',
          styleType: 'error',
          dataCy: 'save-template-description-error-toast',
        });
        return;
      }
      if (!title || !editor || !companyIdNum || !courseIdNum) return;

      const htmlContent = editor.getHtml();
      const components = editor.getWrapper()?.toJSON() ?? '';
      const cssContent = editor.getCss({ json: false }) as string;
      const wrapperClasses: string[] =
        (editor.getWrapper()?.getClasses() as string[]) ?? [];
      const hasWrapper: boolean = wrapperClasses.includes(wrapperClass);

      try {
        deleteEditTimeouts();

        await createTemplatePage({
          companyId: companyIdNum,
          courseId: courseIdNum,
          cssContent,
          hasWrapper,
          htmlContent,
          templateOptions,
          title,
          fonts: selectedCourse?.fonts ?? [],
          components: JSON.stringify(components),
        });

        setEditTemplateTimeout();

        addToast({
          title: 'Template saved',
          styleType: 'success',
          dataCy: 'save-template-success-toast',
        });
      } catch (error) {
        addToast({
          title: 'Error on save template',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          dataCy: 'save-template-error-toast',
        });
      }
    },
    [addToast, companyId, courseId, editor, selectedCourse?.fonts]
  );

  const handleNewCourseSettings = useCallback(() => {
    setNeedSave(true);
  }, []);

  const handleGlobalStyleChanged = useCallback(() => {
    setLoadingCoursePages(true);

    syncCoursePages(Number(courseId))
      .then((result: ICoursePage[]) => {
        lastCoursePages.current = result;
      })
      .catch((err: IApiThrowsError) => {
        Logger.error(err.message);
      })
      .finally(() => {
        setLoadingCoursePages(false);
      });
  }, [courseId, syncCoursePages]);

  const handleDiscardCourse = useCallback(
    (restoredCoursePages: ICoursePage[]) => {
      const selectedCoursePage = coursePages.at(selectedCoursePageIndex);

      let newIndex = 0;
      if (selectedCoursePage) {
        if (
          selectedCoursePage.id ===
          restoredCoursePages.at(selectedCoursePageIndex)?.id
        ) {
          newIndex = selectedCoursePageIndex;
        } else if (!(selectedCoursePage.isNew ?? false)) {
          const newIndexFounded = restoredCoursePages.findIndex(
            createFindById(selectedCoursePage)
          );

          newIndex = newIndexFounded < 0 ? 0 : newIndexFounded;
        }
      }

      const newSelectedCoursePage = restoredCoursePages.at(newIndex);
      if (newSelectedCoursePage) {
        const editorWrapperId = editor?.getWrapper()?.getId();
        if (editorWrapperId) {
          editor?.Css.remove(`#${editorWrapperId}`);
        }

        editor && updateEditorWithContent(editor, newSelectedCoursePage);
        selectCoursePageIndex(newIndex);
      }

      const newNeedSave = lastCoursePages.current.some(({ id }) => id < 0);

      coursePages.forEach(({ id, isNewFromTemplate = false }) => {
        if (!isNewFromTemplate || id <= 0) return;

        deleteCoursePage({ id }).catch((error: IApiThrowsError) => {
          const errorMessage =
            error.response && error.response.status < 500
              ? error.response.data.error.message
              : 'An error occurred. Please try again or contact Benefit Education support.';
          addToast({
            title: 'Error on discard course page',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'discard-course-page-error-toast',
          });
        });
      });

      const {
        viewURL = null,
        passcodeProtected = false,
        passcode,
        status = 'In Progress',
      } = selectedCourse ?? {};

      const updatedSettings: ICourseSettingsProps = {
        viewUrl: viewURL?.trim() ?? null,
        status: status ?? 'In Progress',
        passcodeProtected,
        passcode: passcode ?? undefined,
      };

      updateCourseSettings(updatedSettings);

      setNeedSave(newNeedSave);
    },
    [
      addToast,
      coursePages,
      editor,
      selectCoursePageIndex,
      selectedCoursePageIndex,
      selectedCourse,
      updateCourseSettings,
    ]
  );

  const handleDiscardTemplate = useCallback(
    (newTemplatePages: ITemplatePage[]) => {
      const newSelectedTemplatePage = newTemplatePages.at(-1);
      if (newSelectedTemplatePage) {
        editor && updateEditorWithContent(editor, newSelectedTemplatePage);
        selectCoursePageIndex(-1);
      }

      setNeedSave(false);
    },
    [editor, selectCoursePageIndex]
  );

  const sendToUpdate = useCallback(
    async (
      batchItems: IPatchCoursePagesProps | null
    ): Promise<ICourseDTO | never | null> => {
      try {
        await saveCourse();
      } catch (error) {
        if (isAxiosError(error)) {
          const { response } = error as IApiThrowsError;
          if (
            response?.data.error.message !==
            'Course URL already in use, please try another one'
          ) {
            throw error;
          }
          addToast({
            title: 'URL already in use',
            description: response.data.error.message,
            styleType: 'error',
            dataCy: 'error-toast',
          });
          return null;
        }

        throw error;
      }
      if (batchItems) {
        const course = await patchCoursePages(batchItems);
        updateCourse(course);
        return course;
      }
      return null;
    },
    [addToast, saveCourse, updateCourse]
  );

  const updateCoursePagesWithEditor = useCallback(
    (editor: Editor, index: number) => {
      const htmlContent = editor.getHtml();
      const cssContent = editor.getCss({ json: false }) as string;
      const wrapperClasses: string[] =
        (editor.getWrapper()?.getClasses() as string[]) ?? [];
      const hasWrapper: boolean = wrapperClasses.includes(wrapperClass);

      const components = JSON.stringify(editor.getWrapper()?.toJSON() ?? '');

      const newCoursePages = changeCoursePageContent(index, {
        cssContent,
        htmlContent,
        hasWrapper,
        components,
      });

      if (!needSave) {
        const oldCoursePage = lastCoursePages.current.at(index);
        const newCoursePage = newCoursePages.at(index);

        if (!oldCoursePage || !newCoursePage) {
          setNeedSave(true);
        } else {
          const needToBeSaved = checkContentChanged(
            newCoursePage,
            oldCoursePage
          );
          setNeedSave(needToBeSaved);
        }
      }

      return { newCoursePages, index };
    },
    [changeCoursePageContent, needSave]
  );

  const handleSaveModifications = useCallback(
    async (updatedPages?: ICoursePage[]) => {
      const courseIdFromPath = Number(courseId);
      if (!isAllowedToEdit || !courseIdFromPath) {
        return;
      }
      setSavingCourse(true);

      const htmlContent = editor?.getHtml() ?? '';
      const components = editor?.getWrapper()?.toJSON() ?? '';
      const cssContent = editor?.getCss({ json: false }) ?? '';

      const wrapperClasses: string[] =
        (editor?.getWrapper()?.getClasses() as string[]) ?? [];
      const hasWrapper: boolean = wrapperClasses.includes(wrapperClass) ?? true;

      const newCoursePages =
        selectedCoursePageIndex >= 0
          ? produce(coursePages, (draft) => {
              draft[selectedCoursePageIndex].hasWrapper = hasWrapper;
              draft[selectedCoursePageIndex].htmlContent = htmlContent;
              draft[selectedCoursePageIndex].cssContent = cssContent;
              draft[selectedCoursePageIndex].edited = true;
              draft[selectedCoursePageIndex].components =
                JSON.stringify(components);
            })
          : coursePages;

      const batchItems = updateCoursePages({
        courseId: courseIdFromPath,
        coursePages: newCoursePages,
        updatedPages: updatedPages ?? coursePages,
        lastCoursePages: [...lastCoursePages.current],
      });
      const currentCoursePage = newCoursePages.at(selectedCoursePageIndex);

      try {
        deleteEditTimeouts();

        const courseUpdated = await sendToUpdate(batchItems);
        if (courseUpdated) {
          setEditCourseTimeout();

          const { pages } = courseUpdated;
          pages.sort(ByPageNumber);

          overwriteCoursePages(pages);
          lastCoursePages.current = pages;

          if (currentCoursePage) {
            // handle to persist the same page selected
            const isNewPage = currentCoursePage.id <= 0;

            const newSelectedCoursePageIndex = pages.findIndex(
              isNewPage
                ? createFindByContent(currentCoursePage)
                : createFindById(currentCoursePage)
            );

            const page = pages[newSelectedCoursePageIndex];

            if (editor) {
              if (newSelectedCoursePageIndex >= 0) {
                if (
                  newSelectedCoursePageIndex !== selectedCoursePageIndex ||
                  updatedPages
                ) {
                  selectCoursePageIndex(newSelectedCoursePageIndex);
                  updateEditorWithContent(editor, page);
                }
              } else if (pages.length) {
                selectCoursePageIndex(0);
                updateEditorWithContent(editor, pages[0]);
              }
            }
          }

          setNeedSave(false);
        }
      } catch (err) {
        const title = 'Unable to save changes';
        const styleType = 'error';
        let description = DefaultDescriptionBenefitEducation;
        if (isAxiosError(err)) {
          const error = err as IApiThrowsError;

          description =
            error.response?.data.title ??
            error.response?.data?.message ??
            DefaultDescriptionBenefitEducation;
        }
        addToast({
          title,
          description,
          styleType,
        });
      } finally {
        setSavingCourse(false);
      }
    },
    [
      addToast,
      courseId,
      coursePages,
      editor,
      isAllowedToEdit,
      overwriteCoursePages,
      selectCoursePageIndex,
      selectedCoursePageIndex,
      sendToUpdate,
    ]
  );

  const handleSaveModificationsCourseColors = useCallback(
    async (pages: ICoursePage[]) => {
      await handleSaveModifications(pages);
    },
    [handleSaveModifications]
  );

  const handleChangePagesOrder = useCallback(() => {
    setNeedSave(true);
  }, []);

  const handleSelectPage = useCallback(
    (index: number, oldIndex: number, updateEditor = true) => {
      if (editor) {
        updateCoursePagesWithEditor(editor, oldIndex);
        const page = coursePages.at(index);
        updateEditor &&
          updateEditorWithContent(
            editor,
            page ?? { cssContent: '', htmlContent: '' }
          );
      }
    },
    [coursePages, editor, updateCoursePagesWithEditor]
  );

  const handleRenamePage = useCallback(() => {
    setNeedSave(true);
  }, []);

  const handleRestorePage = useCallback(
    (index: number, historyPage: IHistoryPage) => {
      setNeedSave(true);

      if (index === selectedCoursePageIndex) {
        editor &&
          updateEditorWithContent(editor, {
            htmlContent: historyPage.page.htmlContent ?? '',
            cssContent: historyPage.page.cssContent ?? '',
            components: historyPage.page.components ?? '{}',
            hasWrapper: historyPage.page.hasWrapper ?? true,
          });
      }
    },
    [editor, selectedCoursePageIndex]
  );

  const handleDeletePage = useCallback(
    ({ indexToSelected, idRemoved, newCoursePages }: IDeleteCoursePageInfo) => {
      if (indexToSelected !== undefined && editor) {
        const page = newCoursePages?.at(indexToSelected);
        updateEditorWithContent(
          editor,
          page ?? { cssContent: '', htmlContent: '' }
        );
      }

      if (idRemoved > 0) {
        const page = {
          ...lastCoursePages.current.find(createFindById({ id: idRemoved })),
        };

        if (page) {
          page.id = generateNegativeTimestamp();
          setNeedSave(true);
        }
      }

      setIsDropdownOpen(false);
    },
    [editor]
  );

  const handleSetEditor = useCallback(
    (editor: Editor) => {
      setLoadingEditor(true);
      setEditor(editor);
      if (templateId && templatePages.length) {
        updateEditorWithContent(
          editor,
          templatePages[templatePages.length - 1]
        );
      } else {
        const firstIndex =
          selectedCoursePageIndex > 0 ? selectedCoursePageIndex : 0;
        const firstPage = coursePages.at(firstIndex);

        if (firstPage) {
          updateEditorWithContent(editor, firstPage);
          selectCoursePageIndex(firstIndex);
        }
      }
      setLoadingEditor(false);
    },
    [
      coursePages,
      selectCoursePageIndex,
      templateId,
      templatePages,
      selectedCoursePageIndex,
    ]
  );

  const handleDestroyedEditor = useCallback(() => {
    setEditor(null);
  }, []);

  const handleChangedFontsToEditor = useCallback(
    (newFonts: IFontDTO[]) => {
      if (editor) {
        const styleManager = editor.StyleManager;
        const fontFamily = styleManager.getBuiltIn('font-family');
        const head = editor.Canvas.getDocument().head;

        // @ts-expect-error: the options is missing in PropertyProps
        let customFontsCount = fontFamily?.options.length - defaultFontsCount;
        while (customFontsCount--) {
          // @ts-expect-error: the options is missing in PropertyProps
          fontFamily?.options.pop();
        }

        newFonts.forEach((font: IFontDTO) => {
          // @ts-expect-error: the options is missing in PropertyProps
          fontFamily?.options.push({
            label: `${font.family}`,
            id: `"${font.family}", ${font.category}`,
          } as ICustomFont);
          head.insertAdjacentHTML(
            'beforeend',
            `<link href="${GOOGLE_FONTS_API_URL}?family=${font.family}" rel="stylesheet">`
          );
        });

        if (fontFamily) {
          styleManager.addBuiltIn('font-family', fontFamily);
        }

        styleManager.render();
      }
    },
    [defaultFontsCount, editor]
  );

  const loadBackground = useCallback(() => {
    if (
      editor &&
      (Boolean(coursePages.length) || Boolean(templatePages.length))
    ) {
      const openedPageCourse = coursePages.at(selectedCoursePageIndex);
      const openedPageTemplate = templatePages[templatePages.length - 1];
      const openedPage = openedPageCourse ?? openedPageTemplate;

      const components = openedPage?.components;
      if (components) {
        const pageComponentsJSON = JSON.parse(components) as {
          attributes: { id: string };
        };
        const body = editor.getWrapper();
        body?.setAttributes({ id: pageComponentsJSON.attributes?.id ?? '' });
      }
    }
  }, [coursePages, editor, selectedCoursePageIndex, templatePages]);

  useEffect(() => {
    const css = editor?.Css;

    if (css) {
      const globalStyle = selectedCourse?.globalStyle;

      if (globalStyle) {
        css.setRule('body', {
          color: globalStyle.paragraphFontColor,
          'font-size': `${String(globalStyle.paragraphFontSize)}${String(
            globalStyle.paragraphFontSizeUnit
          )}`,
        } as CSS.StandardPropertiesHyphen);

        css.setRule('.heading', {
          color: globalStyle.headlineFontColor,
          'font-size': `${String(globalStyle.headlineFontSize)}${String(
            globalStyle.headlineFontSizeUnit
          )}`,
        } as CSS.StandardPropertiesHyphen);

        if (globalStyle?.paragraphFont?.family) {
          css.setRule('body', {
            'font-family':
              globalStyle.paragraphFont.family +
              ', ' +
              globalStyle.paragraphFont.category,
            color: globalStyle.paragraphFontColor,
            'font-size': `${String(globalStyle.paragraphFontSize)}${String(
              globalStyle.paragraphFontSizeUnit
            )}`,
          } as CSS.StandardPropertiesHyphen);
        }

        if (globalStyle?.headlineFont?.family) {
          css.setRule('.heading', {
            'font-family':
              globalStyle.headlineFont.family +
              ', ' +
              globalStyle.headlineFont.category,
            color: globalStyle.headlineFontColor,
            'font-size': `${String(globalStyle.headlineFontSize)}${String(
              globalStyle.headlineFontSizeUnit
            )}`,
          } as CSS.StandardPropertiesHyphen);
        }

        if (globalStyle?.buttonFontColor && globalStyle?.buttonColor) {
          css.setRule('.mailto-link', {
            'background-color': globalStyle.buttonColor,
            border: '1px solid ' + globalStyle.buttonColor,
            color: globalStyle.buttonFontColor,
            'border-radius': '15px',
            display: 'inline-block',
            padding: '10px 25px',
            'text-decoration': 'none',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.link-button', {
            'background-color': globalStyle.buttonColor,
            border: '1px solid ' + globalStyle.buttonColor,
            color: globalStyle.buttonFontColor,
            'border-radius': '15px',
            display: 'inline-block',
            padding: '10px 25px',
            'text-decoration': 'none',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.link-document', {
            'background-color': globalStyle.buttonColor,
            border: '1px solid ' + globalStyle.buttonColor,
            color: globalStyle.buttonFontColor,
            'border-radius': '15px',
            display: 'inline-block',
            padding: '10px 25px',
            'text-decoration': 'none',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.outline-button', {
            'background-color': 'transparent',
            color: globalStyle.buttonColor,
            'border-width': '2px',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.outline-button:hover', {
            'background-color': 'transparent',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.mailto-link:hover', {
            'background-color': globalStyle.buttonColor,
            opacity: '0.9',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.link-button:hover', {
            'background-color': globalStyle.buttonColor,
            opacity: '0.9',
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.link-document:hover', {
            'background-color': globalStyle.buttonColor,
            opacity: '0.9',
          } as CSS.StandardPropertiesHyphen);

          const primaryColor = globalStyle.primaryColor
            ? globalStyle.primaryColor
            : globalStyle.buttonColor; // is not globalStyleDefault.primaryColor because of old courses

          css.setRule('.primary-cl', {
            color: primaryColor,
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.primary-bg', {
            'background-color': primaryColor,
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.secondary-cl', {
            color:
              globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor,
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.secondary-bg', {
            'background-color':
              globalStyle.secondaryColor ?? globalStyleDefault.secondaryColor,
          } as CSS.StandardPropertiesHyphen);

          css.setRule('.color-filter', {
            filter: `hue-rotate(${
              globalStyle.hueRotate ?? globalStyleDefault.hueRotate
            }deg)`,
          } as CSS.StandardPropertiesHyphen);
        }

        if (globalStyle?.pageWidth !== null) {
          const pageWidthAux =
            String(globalStyle.pageWidth) + (globalStyle.pageWidthUnit ?? '');
          css.setRule('.content-center', {
            padding: `0 calc((100% - ${pageWidthAux})/2)`,
          } as CSS.StandardPropertiesHyphen);
        }
      }
      loadBackground();
    }
  }, [
    selectedCourse?.globalStyle,
    editor,
    selectedCoursePageIndex,
    loadBackground,
  ]);

  const loadingInitial = loadingCourse || loadingCoursePages;

  const handleAddPage = useCallback(
    (newPage: ICoursePage, newSelectPosition: number) => {
      if (editor) {
        const hadPageSelected = newSelectPosition > 0;
        if (hadPageSelected && newSelectPosition !== selectedCoursePageIndex) {
          const selectedIndexAfterAdded =
            newSelectPosition < selectedCoursePageIndex
              ? selectedCoursePageIndex + 1
              : selectedCoursePageIndex;
          updateCoursePagesWithEditor(editor, selectedIndexAfterAdded);
        }
        updateEditorWithContent(editor, newPage);
      }
      selectCoursePageIndex(newSelectPosition);
      setNeedSave(true);

      if (newPage.id > 0) {
        selectCourseByID(Number(courseId))
          .then((syncedCourse) => {
            handleChangedFontsToEditor(syncedCourse?.fonts ?? []);
          })
          .catch((err) => {
            Logger.error('error: ', err);
            addToast({
              title: 'Error while synchronizing the Course',
              description:
                'An error occurred. Please try again or contact Benefit Education support.',
              styleType: 'error',
              dataCy: 'sync-course-error-toast',
            });
          });
      }
    },
    [
      addToast,
      courseId,
      editor,
      handleChangedFontsToEditor,
      selectCourseByID,
      selectCoursePageIndex,
      selectedCoursePageIndex,
      updateCoursePagesWithEditor,
    ]
  );

  const handleDeleteMultiplePages = useCallback(
    async (ids: number[]) => {
      if (!ids.length) return;
      if (!editor) return;

      try {
        const tmpCoursePages = updateCoursePagesWithEditor(
          editor,
          selectedCoursePageIndex
        );

        const pageSelected = tmpCoursePages.newCoursePages.at(
          selectedCoursePageIndex
        );

        const { newCoursePages } = await deleteMultipleCoursePages(
          ids,
          Number(courseId),
          tmpCoursePages.newCoursePages
        );

        let newSelectedCoursePageIndex = 0;
        // if pageSelected is deleted, select the first page
        if (pageSelected) {
          if (newCoursePages.findIndex(createFindById(pageSelected)) < 0) {
            if (selectedCoursePageIndex >= newCoursePages.length) {
              newSelectedCoursePageIndex = newCoursePages.length - 1;
            } else {
              newSelectedCoursePageIndex = selectedCoursePageIndex;
            }
          } else {
            newSelectedCoursePageIndex = newCoursePages.findIndex(
              createFindById(pageSelected)
            );
          }
        }

        const page = newCoursePages.at(newSelectedCoursePageIndex);

        if (!page) return;
        selectCoursePageIndex(newSelectedCoursePageIndex);
        updateEditorWithContent(editor, page);

        addToast({
          title: 'Pages deleted',
          styleType: 'success',
          dataCy: 'delete-pages-success-toast',
        });
      } catch (error) {
        addToast({
          title: 'Unable to delete the pages',
          styleType: 'error',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
        });
      }
    },
    [
      addToast,
      courseId,
      deleteMultipleCoursePages,
      editor,
      selectCoursePageIndex,
      selectedCoursePageIndex,
      updateCoursePagesWithEditor,
    ]
  );

  return (
    <Container data-cy="page-container">
      <FullscreenLoader notLoading={!savingCourse}></FullscreenLoader>
      <SettingCourseHeader
        isCreateCourse={Boolean(courseId)}
        isAllowedToEdit={
          !isLockedByOtherUser && (isAllowedToEdit || !savingCourse)
        }
        isDropdownOpen={isDropdownOpen}
        isUserManager={isUserManager}
        isUserAdmin={isUserAdmin}
        lastCoursePages={lastCoursePages}
        lastTemplatePage={lastTemplatePage}
        loading={loadingInitial}
        needSave={needSave}
        onChangeTitle={handleChangeTitle}
        onCourseFontsChange={handleChangedFontsToEditor}
        onDiscardCourse={handleDiscardCourse}
        onDiscardTemplate={handleDiscardTemplate}
        onSaveModifications={handleSaveModifications}
        onSavePageTemplate={handleSavePageTemplateAndCourse} // using when save template and courses
        onUpdatePageTemplate={handleUpdateTemplate} // using when Edit Course is opened from the template list screen
        onSettingsChange={handleNewCourseSettings}
        onSaveGlobalStyle={handleGlobalStyleChanged}
        onBackButtonAdditionalAction={deleteEditTimeouts}
      />
      <MainContainer data-cy="page-main-container">
        {courseId && (
          <CoursePages
            disabled={savingCourse || loadingEditor || loadingInitial}
            isAllowedToEdit={isAllowedToEdit}
            isDropdownOpen={isDropdownOpen}
            loading={loadingInitial}
            // handlerUpdatePageFromEditor={handleUpdatePageFromEditor}
            onAddPage={handleAddPage}
            onChangePagesOrder={handleChangePagesOrder}
            onDeletePage={handleDeletePage}
            onDropdownOpenChange={setIsDropdownOpen}
            onRenamePage={handleRenamePage}
            onRestorePage={handleRestorePage}
            onSelectPage={handleSelectPage}
            onSaveModifications={handleSaveModificationsCourseColors}
            onDeletedMultiplePages={handleDeleteMultiplePages}
          />
        )}
        <MainContent data-cy="page-main-content">
          {loadingInitial || loadingPluginConfig ? (
            <LoadingCoursePagesContainer data-cy="loading-course-pages-container">
              <LoadingCoursePages dataCy="loading-course-pages" />
            </LoadingCoursePagesContainer>
          ) : (courseId && coursePages.length) ||
            (templateId && selectedTemplate) ? (
            <WebBuilder
              customConfig={customConfig}
              isDraggingOver={isDragging}
              onEditorReady={handleSetEditor}
              onEditorDestroyed={handleDestroyedEditor}
              documentLinkOptions={documentLinkPluginConfig}
              fonts={selectedCourse?.fonts ?? []}
              setDefaultFontsCount={setDefaultFontsCount}
            />
          ) : (
            <NoPagesContainer data-cy="noPages-container">
              <NoPagesText data-cy="text-noPage-edit">
                No page to edit
              </NoPagesText>
            </NoPagesContainer>
          )}
        </MainContent>
      </MainContainer>
    </Container>
  );
};
