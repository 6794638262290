import { MdDragIndicator } from 'react-icons/md';
import styled from 'styled-components';

import { CheckBoxGroup } from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

import { FontCard } from '../../../../pages/EditCourse/components/FontCard';

export const FontCardCustom = styled(FontCard)``;
export const Container = styled(CheckBoxGroup)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  color: ${colors.blue300};
  & ${FontCardCustom} + ${FontCardCustom} {
    margin-top: 0.125rem;
  }
`;

export const Fieldset = styled.fieldset`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.75rem;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};
  }
`;

export const Input = styled.input`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
`;

export const CardsContainer = styled.div`
  color: ${colors.blue300};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc((85vh - 60px - 15rem) / 2);
  width: 100%;
  position: relative;
  overflow-y: auto;
  width: 100%;

  ${scrollbar};
  &:not(:hover) {
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
  }
`;

export const CheckBoxCardContainer = styled.div`
  color: ${colors.blue300};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Divider = styled.div`
  background-color: ${colors.divider};
  height: 1px;
  margin: 0.375rem 0;
  width: 100%;
`;

export const ActionButton = styled.div`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: grab;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const DragIcon = styled(MdDragIndicator)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const LabelNoFonts = styled.p`
  margin: 0.25rem;
  color: ${colors.black300};
  text-align: center;
`;
