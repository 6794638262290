import { z } from 'zod';

import { color } from './utils/color';

export const templateColorsUpdate = z.object({
  primaryColor: color.optional(),
  secondaryColor: color.optional(),
  backgroundColor: color.optional(),
  accentColor: color.optional(),
});
export const courseSettingSchema = z.object({
  viewUrl: z.string().trim().nullable(),
  passcodeProtected: z.boolean().optional(),
  passcode: z.string().trim().nullable(),
  status: z.string().nullable(),
  templateColors: templateColorsUpdate,
});

export type CourseSettingSchema = z.infer<typeof courseSettingSchema>;
export type ITemplateColorsUpdate = z.infer<typeof templateColorsUpdate>;
