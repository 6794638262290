import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  BaseCheckbox,
  FormBase,
  SelectData,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type IDialogModalProps } from '../../../../components/DialogModal';
import { globalStyleDefault } from '../../../../constants/GlobalStyles';
import {
  type CourseSettingSchema,
  courseSettingSchema,
} from '../../../../formSchemas/courseSchema';
import { useCourse } from '../../../../hooks/useCourse';
import { type ICourseSettingsProps } from '../../../../services/courses';
import { ColorPickerInput } from '../ColorPickerInput';
import { TemplateImagesColorsTitle } from '../GlobalStyleModal/styles';
import {
  CheckContainer,
  FieldsetCheckBox,
  FormModalCustom,
  LabelCheckBox,
  LockedIcon,
  TemplateImagesColorsSection,
  UnLockedIcon,
} from './styles';

interface ISettingCourseModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  courseTitle?: string;
  onAccept: (data: CourseSettingSchema) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  courseSettings: ICourseSettingsProps | null;
}

export const SettingCourseModal = ({
  courseTitle = 'this',
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  courseSettings,
  ...props
}: ISettingCourseModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const [passcodeProtected, setPasscodeProtected] = useState(false);
  const [showPasscode, setShowPasscode] = useState(false);
  const { selectedCourse } = useCourse();

  const courseSettingSchemaModal = useForm<CourseSettingSchema>({
    resolver: zodResolver(courseSettingSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      passcode: courseSettings?.passcode ?? '',
      viewUrl: courseSettings?.viewUrl ?? '',
      status: courseSettings?.status ?? 'In Progress',
      passcodeProtected: false,
      templateColors:
        selectedCourse?.globalStyle?.templateColors ??
        globalStyleDefault.templateColors,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = courseSettingSchemaModal;
  const values = watch();
  const resetForm = useCallback(() => {
    setValue('passcode', courseSettings?.passcode ?? '');
    setValue('viewUrl', courseSettings?.viewUrl ?? '');
    setValue('status', courseSettings?.status ?? 'In Progress');
    const oldNewTemplateColors =
      selectedCourse?.globalStyle?.templateColors ??
      globalStyleDefault.templateColors;
    setValue('templateColors', oldNewTemplateColors);
  }, [setValue, courseSettings, selectedCourse?.globalStyle?.templateColors]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [courseSettings, resetForm, setValue, open]);

  const handlePasscodeProtectedChange = useCallback(
    (checked: boolean) => {
      setPasscodeProtected(checked);
      setValue('passcode', '');
    },
    [setValue]
  );

  const onSubmit: SubmitHandler<CourseSettingSchema> = (
    data: CourseSettingSchema
  ) => {
    const updatedData = {
      ...data,
      passcodeProtected,
    };
    void onAccept?.(updatedData);
  };

  const handleDeclineNewCourseSettings = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [resetForm, onDecline]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const handleTemplatePrimaryColorChange = useCallback(
    (color: string) => {
      setValue('templateColors.primaryColor', color);
      void trigger('templateColors.primaryColor');
    },
    [setValue, trigger]
  );
  const handleTemplateSecondaryColorChange = useCallback(
    (color: string) => {
      setValue('templateColors.secondaryColor', color);
      void trigger('templateColors.secondaryColor');
    },
    [setValue, trigger]
  );
  const handleTemplateBackgroundColorChange = useCallback(
    (color: string) => {
      setValue('templateColors.backgroundColor', color);
      void trigger('templateColors.backgroundColor');
    },
    [setValue, trigger]
  );
  const handleTemplateAccentColorChange = useCallback(
    (color: string) => {
      setValue('templateColors.accentColor', color);
      void trigger('templateColors.accentColor');
    },
    [setValue, trigger]
  );
  const hasAnyTemplateColor = useMemo(
    () =>
      [
        values.templateColors.primaryColor,
        values.templateColors.secondaryColor,
        values.templateColors.backgroundColor,
        values.templateColors.accentColor,
      ].some((value) => value),
    [values.templateColors]
  );
  return !open ? null : (
    <FormBase.Provider {...courseSettingSchemaModal}>
      <FormModalCustom
        acceptText="Save"
        dataCy="setting-course-dialog-modal"
        declineText="Cancel"
        mainText={`${courseTitle} settings`}
        {...props}
        onAccept={handleSubmit(onSubmit)}
        onDecline={handleDeclineNewCourseSettings}
        onOpenChange={handleDeclineNewCourseSettings}
        open={!waitToOpen}
      >
        <FormBase.Content>
          <FieldsetCheckBox>
            <CheckContainer data-cy="passcode-checkbox-container">
              <BaseCheckbox
                id="change-passcode"
                dataCy={'passcode-checkbox'}
                name="change-passcode"
                onCheckedChange={(checked) => {
                  handlePasscodeProtectedChange(checked.valueOf() === true);
                }}
              />
            </CheckContainer>
            <LabelCheckBox data-cy="label-checkbox" htmlFor="change-passcode">
              Change Passcode
            </LabelCheckBox>
          </FieldsetCheckBox>

          <FormBase.InputContent
            filled={!!values?.passcode}
            inputRef="passcode"
            label="Passcode"
            errorMessage={errors.passcode?.message}
            dataCy="changePassword-fieldSet"
          >
            <FormBase.InputText
              dataCy="passcode-input"
              id="passcode"
              name="passcode"
              type={showPasscode ? 'text' : 'password'}
              maxLength={20}
              autoComplete="off"
              disabled={!passcodeProtected}
            />
            <FormBase.InputActions
              icon={showPasscode ? LockedIcon : UnLockedIcon}
              onClick={() => {
                setShowPasscode(!showPasscode);
              }}
              dataCy={
                showPasscode ? 'locked-icon-confirm' : 'unlocked-icon-confirm'
              }
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.viewUrl}
            inputRef="view-url"
            label="Course Link"
            errorMessage={errors.viewUrl?.message}
            dataCy="settings-fieldSet"
          >
            <FormBase.InputText
              dataCy="view-urlInput"
              id="view-url"
              name="viewUrl"
              type="text"
              maxLength={100}
              autoComplete="off"
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.status}
            value={String(values.status)}
            inputRef="select-state"
            label="Status"
            errorMessage={errors.status?.message}
            dataCy="label-state"
            isSelectData
          >
            <SelectData
              data={[
                { key: 'Completed', value: 'Completed' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Disabled', value: 'Disabled' },
              ]}
              dataCy="course-status-field"
              name="course-status"
              placeholder="Select Status"
              zIndex={10}
              defaultValue={values.status ?? 'In Progress'}
              onValueChange={(value) => {
                setValue('status', value);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'status');
              }}
            />
          </FormBase.InputContent>

          <TemplateImagesColorsSection hasColors={hasAnyTemplateColor}>
            <TemplateImagesColorsTitle>
              Template Images Colors
            </TemplateImagesColorsTitle>
            {hasAnyTemplateColor && (
              <>
                {values.templateColors.primaryColor !== undefined && (
                  <ColorPickerInput
                    color={values.templateColors.primaryColor}
                    dataCy={`template-color-${values.templateColors.primaryColor}`}
                    errorMessage={errors.templateColors?.primaryColor?.message}
                    label="Primary Color"
                    name="templateColors.primaryColor"
                    onChange={handleTemplatePrimaryColorChange}
                    required={false}
                  />
                )}
                {values.templateColors.secondaryColor !== undefined && (
                  <ColorPickerInput
                    color={values.templateColors.secondaryColor}
                    dataCy={`template-color-${values.templateColors.secondaryColor}`}
                    errorMessage={
                      errors.templateColors?.secondaryColor?.message
                    }
                    label="Secondary Color"
                    name="templateColors.secondaryColor"
                    onChange={handleTemplateSecondaryColorChange}
                    required={false}
                  />
                )}
                {values.templateColors.backgroundColor !== undefined && (
                  <ColorPickerInput
                    color={values.templateColors.backgroundColor}
                    dataCy={`template-color-${values.templateColors.backgroundColor}`}
                    errorMessage={
                      errors.templateColors?.backgroundColor?.message
                    }
                    label="Background Color"
                    name="templateColors.backgroundColor"
                    onChange={handleTemplateBackgroundColorChange}
                    required={false}
                  />
                )}
                {values.templateColors.accentColor !== undefined && (
                  <ColorPickerInput
                    color={values.templateColors.accentColor}
                    dataCy={`template-color-${values.templateColors.accentColor}`}
                    errorMessage={errors.templateColors?.accentColor?.message}
                    label="Accent Color"
                    name="templateColors.accentColor"
                    onChange={handleTemplateAccentColorChange}
                    required={false}
                  />
                )}
              </>
            )}
          </TemplateImagesColorsSection>
        </FormBase.Content>
      </FormModalCustom>
    </FormBase.Provider>
  );
};
