import {
  type IGlobalStyleDTO,
  type IUpdateGlobalStyleCourseProps,
} from '../services/courses';

export interface IGlobalStyle
  extends Omit<Required<IGlobalStyleDTO>, 'createdAt' | 'id'> {}
export interface IUpdateGlobalStyle
  extends Omit<Required<IUpdateGlobalStyleCourseProps>, 'createdAt' | 'id'> {}

export type IAdditionalColors = Record<string, string>;

export const globalStyleDefault = {
  buttonColor: '#00426b',
  buttonFontColor: '#ffffff',
  headlineFont: null,
  headlineFontColor: '#000000',
  headlineFontSize: 24,
  headlineFontSizeUnit: 'px',
  hueRotate: 0,
  pageWidth: 500,
  pageWidthUnit: 'px',
  paragraphFont: null,
  paragraphFontColor: '#000000',
  paragraphFontSize: 16,
  paragraphFontSizeUnit: 'px',
  primaryColor: '#0b70fe',
  secondaryColor: '#c5f1ff',
  templateColors: {
    primaryColor: '',
    secondaryColor: '',
    backgroundColor: '',
    accentColor: '',
  },
  additionalColors: {} as IAdditionalColors,
} satisfies IGlobalStyle;

export const maxHueRotate = 360;
export const minHueRotate = 0;
export const minValuePageWidth = 1;
export const maxValuePageWidth = 900;
export const maxValuePercentagePageWidth = 100;
export const minValueFontSize = 1;
export const maxValueFontSize = 100;
